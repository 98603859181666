import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import settings from '../settings';
import './Login.css';  // Use the same CSS as Login for consistency
import './Dashboard.css';
function Register() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${settings.backendUrl}/api/users/register`, { username, password });
            if (response.data.id) {
                navigate('/login');
            }
        } catch (error) {
            console.error('Registration failed:', error);
        }
    };

    return (
        <div className="login-container">
            <div className="login-form">
                <div className="login-welcome">
                    <h2>Register</h2>
                    <br />
                    <p>Please fill in the details below to create an account</p>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Email</label>
                        <input type="text" className="form-control" value={username} onChange={(e) => setUsername(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <label>Password</label>
                        <input type="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <button type="submit" className="btn btn-primary btn-block">Sign Up</button>
                    </div>
                    <div className="form-group text-center">
                        <p>Already have an account? <a href="/login">Sign in</a></p>
                    </div>
                </form>
            </div>
           
        </div>
    );
}

export default Register;
