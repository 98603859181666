import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

function AddTokenModal({ show, handleClose, handleAddToken, symbol, setSymbol, tokenAddress, setTokenAddress }) {
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add Token</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formTokenSymbol">
                        <Form.Label>Token Symbol</Form.Label>
                        <Form.Control type="text" placeholder="Enter token symbol" value={symbol} onChange={(e) => setSymbol(e.target.value)} />
                    </Form.Group>
                    <Form.Group controlId="formTokenAddress">
                        <Form.Label>Token Address</Form.Label>
                        <Form.Control type="text" placeholder="Enter token address" value={tokenAddress} onChange={(e) => setTokenAddress(e.target.value)} />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleAddToken}>
                    Add Token
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddTokenModal;
