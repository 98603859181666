import React, { useState, useEffect } from 'react';
import axios from 'axios';
import settings from '../settings';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Button, Card, ListGroup, Form, Tabs, Tab, Modal, Spinner, Stack, Badge, Table } from 'react-bootstrap';
import AddTokenModal from '../components/AddTokenModal';
import AddTaskModal from '../components/AddTaskModal';
import { FaBeer, FaClock, FaCoins, FaCross, FaHashtag, FaInfo, FaList, FaPlus, FaPowerOff, FaRobot, FaSkullCrossbones, FaWallet, FaEye } from "react-icons/fa";
import './Dashboard.css';

function Dashboard() {
    const [wallets, setWallets] = useState([]);
    const [balances, setBalances] = useState({});
    const [tasks, setTasks] = useState({});
    const [tokens, setTokens] = useState({});
    const [title, setTitle] = useState('');
    const [symbol, setSymbol] = useState('');
    const [tokenAddress, setTokenAddress] = useState('');
    const [taskType, setTaskType] = useState('');
    const [selectedTokenAddress, setSelectedTokenAddress] = useState('');
    const [showTokenModal, setShowTokenModal] = useState(false);
    const [showTaskModal, setShowTaskModal] = useState(false);
    const [showWalletModal, setShowWalletModal] = useState(false);
    const [selectedWalletId, setSelectedWalletId] = useState(null);
    const [activeKey, setActiveKey] = useState('all');
    const [loading, setLoading] = useState(false);
    const [showPrivateKeyModal, setShowPrivateKeyModal] = useState(false);
    const [selectedPrivateKey, setSelectedPrivateKey] = useState('');
    const navigate = useNavigate();
    const userId = localStorage.getItem('userId');

    useEffect(() => {
        fetchWallets();
        const interval = setInterval(() => {
            fetchWallets();
        }, 60000); // Refresh every 10 seconds
        return () => clearInterval(interval); // Cleanup on unmount
    }, []);

    useEffect(() => {
        if (wallets.length > 0) {
            wallets.forEach(wallet => {
                fetchBalances(wallet.id);
                fetchTasks(wallet.id);
                fetchTokens(wallet.id);
            });
        }
    }, [wallets]);

    const fetchWallets = async () => {
        setLoading(true);
        const response = await axios.get(`${settings.backendUrl}/api/wallets`, {
            headers: { 'User-Id': userId }
        });
        console.log(response.data);
        setWallets(response.data);
        await new Promise(resolve => setTimeout(resolve, 3000));
        setLoading(false);
    };

    const handleCreateWallet = async () => {
        const response = await axios.post(`${settings.backendUrl}/api/wallets/create`, { title }, {
            headers: { 'User-Id': userId }
        });
        setWallets([...wallets, response.data]);
        setTitle('');
        setShowWalletModal(false);
        await fetchWallets();
        await axios.post(`${settings.backendUrl}/api/wallets/add-token`, { walletId: response.data.id, symbol: "DAI", tokenAddress: "0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb" }, {
            headers: { 'User-Id': userId }
        });
        fetchBalances(response.data.id);
        fetchTokens(response.data.id);
    };

    const handleAddToken = async () => {
        await axios.post(`${settings.backendUrl}/api/wallets/add-token`, { walletId: selectedWalletId, symbol, tokenAddress }, {
            headers: { 'User-Id': userId }
        });
        fetchBalances(selectedWalletId);
        fetchTokens(selectedWalletId);
        setShowTokenModal(false);
    };

    const handleDeleteWallet = async (id) => {
        if (window.confirm('Are you sure you want to delete this wallet?')) {
            await axios.delete(`${settings.backendUrl}/api/wallets/${id}`, {
                headers: { 'User-Id': userId }
            });
            fetchWallets();
        }
    };

    const handleDeleteTask = async (id) => {
        if (window.confirm('Are you sure you want to delete this task?')) {
            await axios.delete(`${settings.backendUrl}/api/tasks/${id}`, {
                headers: { 'User-Id': userId }
            });
            await fetchWallets();
        }
    };

    const fetchBalances = async (walletId) => {
        const response = await axios.get(`${settings.backendUrl}/api/wallets/tokens/${walletId}`, {
            headers: { 'User-Id': userId }
        });
        setBalances(prevBalances => ({
            ...prevBalances,
            [walletId]: response.data
        }));
    };

    const fetchTasks = async (walletId) => {
        const response = await axios.get(`${settings.backendUrl}/api/tasks/${walletId}`, {
            headers: { 'User-Id': userId }
        });
        setTasks(prevTasks => ({
            ...prevTasks,
            [walletId]: response.data
        }));
    };

    const fetchTokens = async (walletId) => {
        const response = await axios.get(`${settings.backendUrl}/api/tasks/tokens/${walletId}`, {
            headers: { 'User-Id': userId }
        });
        setTokens(prevTokens => ({
            ...prevTokens,
            [walletId]: response.data
        }));
    };

    const handleAddTask = async (run_time) => {
        console.log(run_time);
        await axios.post(`${settings.backendUrl}/api/tasks/add`, { walletId: selectedWalletId, type: taskType, tokenAddress: selectedTokenAddress, run_time: run_time }, {
            headers: { 'User-Id': userId }
        });
        fetchTasks(selectedWalletId);
        setShowTaskModal(false);
    };

    const handleAddRandomTask = async (walletId) => {
        const availableTaskTypes = ['Swap eth to token', 'Swap token to eth'];
        const randomTaskType = availableTaskTypes[Math.floor(Math.random() * availableTaskTypes.length)];
        const tokensForWallet = tokens[walletId] || [];
        if (tokensForWallet.length === 0) {
            alert('No tokens available for this wallet to add a task.');
            return;
        }
        const randomToken = tokensForWallet[Math.floor(Math.random() * tokensForWallet.length)];
        await axios.post(`${settings.backendUrl}/api/tasks/add`, { walletId, type: randomTaskType, tokenAddress: randomToken.tokenAddress }, {
            headers: { 'User-Id': userId }
        });
        fetchTasks(walletId);
    };

    const handleLogout = () => {
        localStorage.removeItem('userId');
        navigate('/login');
    };

    const handleShowPrivateKey = (privateKey) => {
        setSelectedPrivateKey(privateKey);
        setShowPrivateKeyModal(true);
    };

    return (
        <Container fluid className="dashboard-container">
            <Row className="dashboard-header">
             
            </Row>
            
            <Row className="dashboard-content">
                
                <Col md={12} className="dashboard-section">
                    <h2>Airdrop Bot
                    
                    
                    <Button onClick={handleLogout} variant="secondary" style={{float:"right", marginLeft:"10px"}} ><FaPowerOff className='smallIcon' /> Logout</Button>
                    <Button variant="primary" onClick={() => setShowWalletModal(true)} style={{float:"right", marginLeft:"10px"}} ><FaWallet className='smallIcon' /> Create Wallet</Button>
                    {loading && ( <Spinner animation="border" style={{float:"right", fontWeight:"400", opacity:0.3, marginTop:"5px"}} />)}

                    </h2>
                    
                </Col>
                <Col md={12} className="dashboard-section">
                    <Tabs activeKey={activeKey} onSelect={(k) => setActiveKey(k)}>
                    <Tab eventKey="all" title="All">
                    <Table stripe responsive className='pb-3 mb-3'>
                                            <thead>
                                            <tr>
                                                <th>Wallet</th>
                                                <th colSpan={20}>Tasks</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {wallets.map(wallet => (
                                                <tr key={wallet.id}>
                                                    <td>{wallet.title}
                                                     <br /><small style={{fontSize:"10px"}}>{wallet.address} <Button variant="link" onClick={() => handleShowPrivateKey(wallet.privateKey)}><FaEye /></Button><br />
                                                     
                                                     </small></td>
                                                    
                                                    {tasks[wallet.id]?.filter(task => task.status === 'pending').map(task => (
                                                        <td>
                                                        <Badge pill bg={task.type=="Swap eth to token" ? "primary" : "info"} key={task.id}>
                                                            <small>{task.type}</small> 
                                                            </Badge>
                                                            <br />
                                                            <small style={{fontSize:"10px"}}><FaClock className='smallIcon' />{new Date(task.run_at).toLocaleString()}</small>
                                                            </td>
                                                    ))}
                                                    
                                                </tr>
                                            ))}
                                            </tbody>
                                        </Table>
                            </Tab>
                        {wallets.map(wallet => (
                            <Tab eventKey={wallet.id} title={wallet.title} key={wallet.id}>
                                <Card className="mb-3">
                                    <Card.Body>
                                        <Card.Title className='mt-3 pb-3'>
                                            
                                            <small><FaRobot className='smallIcon' /> Address: <a href={`https://basescan.org/address/${wallet.address}`} target='_blank'>{wallet.address}</a> <Button variant="link" onClick={() => handleShowPrivateKey(wallet.privateKey)}><FaEye /></Button></small>
                                            
                                        
                                            </Card.Title>
                                        <div className='card-text'>
                                      
                                        <Stack direction="horizontal" gap={2} className='mt-3 pb-3'>
                                        <Badge pill bg="primary">
                                        ETH: {parseFloat(balances[wallet.id]?.ethBalance).toFixed(5) || 'Loading...'}
                                        </Badge>
                                        {balances[wallet.id]?.tokenBalances?.map(token => (
                                               <Badge pill bg="primary" key={token.tokenAddress}>
                                               {token.symbol}: {parseFloat(token.balance).toFixed(5)}
                                               </Badge>
                                            ))}
                                        </Stack>
                                        
                                        <Button variant="primary" className='mt-1' onClick={() => { setSelectedWalletId(wallet.id); setShowTaskModal(true); }}><FaPlus className='smallIcon' />Add Task</Button>

                                        <Button variant="warning" className='mt-1' onClick={() => handleAddRandomTask(wallet.id)}><FaBeer className='smallIcon' /> Random Task</Button>
                                        <Button variant="secondary" className='mt-1' onClick={() => { setSelectedWalletId(wallet.id); setShowTokenModal(true); }}><FaCoins className='smallIcon' /> Add Token</Button>
                                       
                                        <Row className='mt-3'>
                                            <Col>
                                                <h4>Pending Tasks</h4>
                                                <ListGroup>
                                                    {tasks[wallet.id]?.filter(task => task.status === 'pending').map(task => (
                                                        <ListGroup.Item key={task.id}>
                                                           <h6><small>{task.type}</small> <Button variant="danger" onClick={() => handleDeleteTask(task.id)} className="mr-2" style={{float:"right", padding:"2px", fontSize:"14px"}}>
                                                            <small><FaSkullCrossbones className='smallIcon'  /></small></Button>
                                                            </h6>
                                                           Run time:
                                                          <Badge pill bg="secondary"><FaClock className='smallIcon' />{new Date(task.run_at).toLocaleString()}</Badge>
                                                           
                                                          {balances[wallet.id]?.tokenBalances?.map(token => ( <span key={token.tokenAddress}>{token.tokenAddress == task.tokenAddress && (<Badge pill bg="primary"> <FaCoins className='smallIcon' /> {token.symbol}</Badge>)}</span>))}
                                                          
                                                          <Badge pill bg="primary"><small><FaHashtag className='smallIcon' /></small> {task.id}</Badge>
                                                         
                                                          
                                                      </ListGroup.Item>
                                                    ))}
                                                </ListGroup>
                                            </Col>
                                            <Col>
                                                <h4>Completed Tasks</h4>
                                                <ListGroup>
                                                    {tasks[wallet.id]?.filter(task => task.status === 'completed').sort((a, b) => b.id - a.id).map(task => (
                                                        <ListGroup.Item key={task.id}>
                                                             <h6><small>{task.type}</small></h6>
                                                             <Badge pill bg="primary"><small><FaHashtag className='smallIcon' /></small>  {task.id}</Badge>
                                                            {balances[wallet.id]?.tokenBalances?.map(token => ( <span key={token.tokenAddress}>{token.tokenAddress == task.tokenAddress && (<Badge pill bg="primary"><FaCoins className='smallIcon' /> {token.symbol}</Badge>)}</span>))}
                                                            Runed at:
                                                            <Badge pill bg="secondary"><FaClock className='smallIcon' /> {new Date(task.updated_at).toLocaleString()}</Badge>
                                                        <hr />
                                                        <small><FaInfo className='smallIcon' /> {task.log}</small>
                                                        </ListGroup.Item>
                                                    ))}
                                                </ListGroup>
                                            </Col>
                                        </Row>
                                        </div>
                                
                                        
                                        
                                    </Card.Body>
                                    <Card.Footer>
                                        
                                    </Card.Footer>
                                </Card>
                            </Tab>
                        ))}
                    </Tabs>
                </Col>
            </Row>
            <AddTokenModal
                show={showTokenModal}
                handleClose={() => setShowTokenModal(false)}
                handleAddToken={handleAddToken}
                symbol={symbol}
                setSymbol={setSymbol}
                tokenAddress={tokenAddress}
                setTokenAddress={setTokenAddress}
            />
            <AddTaskModal
                show={showTaskModal}
                handleClose={() => setShowTaskModal(false)}
                handleAddTask={handleAddTask}
                taskType={taskType}
                setTaskType={setTaskType}
                selectedTokenAddress={selectedTokenAddress}
                setSelectedTokenAddress={setSelectedTokenAddress}
                tokens={tokens[selectedWalletId] || []}
            />
            <Modal show={showWalletModal} onHide={() => setShowWalletModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Create Wallet</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formWalletTitle">
                            <Form.Label>Wallet Title</Form.Label>
                            <Form.Control type="text" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Wallet Title" />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowWalletModal(false)}>Close</Button>
                    <Button variant="primary" onClick={handleCreateWallet}>Create</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showPrivateKeyModal} onHide={() => setShowPrivateKeyModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Private Key</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Private Key</Form.Label>
                        <Form.Control type="text" readOnly value={selectedPrivateKey} style={{fontSize:"10px"}} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowPrivateKeyModal(false)}>Close</Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}

export default Dashboard;
