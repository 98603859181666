import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Datetime from 'react-datetime';
import moment from 'moment';
import 'react-datetime/css/react-datetime.css';

function AddTaskModal({ show, handleClose, handleAddTask, taskType, setTaskType, selectedTokenAddress, setSelectedTokenAddress, tokens }) {
    const [taskRunTime, setTaskRunTime] = useState(moment().add(1, 'hour'));

    const handleAddTaskWithTime = () => {
        const taskRunTimeFormatted = taskRunTime.format('YYYY-MM-DD HH:mm');
        handleAddTask(taskRunTimeFormatted);
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add Task</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formTaskType">
                        <Form.Label>Task Type</Form.Label>
                        <Form.Control as="select" value={taskType} onChange={(e) => setTaskType(e.target.value)}>
                            <option value="">Select Task Type</option>
                            <option value="Swap eth to token">Swap ETH to Token</option>
                            <option value="Swap token to eth">Swap Token to ETH</option>
                            <option value="Buy NFT">Buy NFT</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="formTokenAddress">
                        <Form.Label>Token</Form.Label>
                        <Form.Control as="select" value={selectedTokenAddress} onChange={(e) => setSelectedTokenAddress(e.target.value)}>
                            <option value="">Select Token</option>
                            {tokens.map(token => (
                                <option key={token.tokenAddress} value={token.tokenAddress}>
                                    {token.symbol}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="formTaskRunTime">
                        <Form.Label>Run Time</Form.Label>
                        <Datetime
                            value={taskRunTime}
                            onChange={(date) => setTaskRunTime(date)}
                            inputProps={{ placeholder: 'Select Date and Time' }}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleAddTaskWithTime}>
                    Add Task
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddTaskModal;
