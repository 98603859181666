import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import settings from '../settings';
import './Login.css';
import './Dashboard.css';

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${settings.backendUrl}/api/users/login`, { username, password });
            if (response.data.id) {
                localStorage.setItem('userId', response.data.id);
                navigate('/');
            }
        } catch (error) {
            console.error('Login failed:', error);
        }
    };

    return (
        <div className="login-container">
            <div className="login-form">
                <div className="login-welcome">
                    <h2>Login</h2>
                    <p>Please enter log in details below</p>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Email</label>
                        <input type="text" className="form-control" value={username} onChange={(e) => setUsername(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <label>Password</label>
                        <input type="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <button type="submit" className="btn btn-primary btn-block">Sign in</button>
                    </div>
                   
                    <div className="form-group text-center">
                        <p>Don't have an account? <a href="/register">Sign Up</a></p>
                    </div>
                </form>
            </div>
            
        </div>
    );
}

export default Login;
